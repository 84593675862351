var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"register-wrap d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Регистрация ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"Фамилия","label-for":"xbu_LN"}},[_c('validation-provider',{attrs:{"name":"Фамилия","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"xbu_LN","state":errors.length > 0 ? false:null,"name":"xbu_LN"},model:{value:(_vm.formData.xbu_LN),callback:function ($$v) {_vm.$set(_vm.formData, "xbu_LN", $$v)},expression:"formData.xbu_LN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Имя","label-for":"xbu_FN"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"xbu_FN","state":errors.length > 0 ? false:null,"name":"xbu_FN"},model:{value:(_vm.formData.xbu_FN),callback:function ($$v) {_vm.$set(_vm.formData, "xbu_FN", $$v)},expression:"formData.xbu_FN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Отчество","label-for":"xbu_MN"}},[_c('validation-provider',{attrs:{"name":"Отчество"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"xbu_MN","name":"xbu_MN"},model:{value:(_vm.formData.xbu_MN),callback:function ($$v) {_vm.$set(_vm.formData, "xbu_MN", $$v)},expression:"formData.xbu_MN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Дата рождения","label-for":"xx_bithday"}},[_c('validation-provider',{attrs:{"name":"Дата рождения"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"w-100",attrs:{"format":"DD.MM.YYYY","placeholder":"дд.мм.гггг"},model:{value:(_vm.formData.xx_bithday),callback:function ($$v) {_vm.$set(_vm.formData, "xx_bithday", $$v)},expression:"formData.xx_bithday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Город","label-for":"xx_town"}},[_c('validation-provider',{attrs:{"name":"Город"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"xx_town","name":"xx_town"},model:{value:(_vm.formData.xx_town),callback:function ($$v) {_vm.$set(_vm.formData, "xx_town", $$v)},expression:"formData.xx_town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.zbid && !_vm.$route.query.bc && !_vm.$route.params.pathMatch)?_c('b-form-group',{attrs:{"label":"Код партнера или промокод","label-for":"xx_ref"}},[_c('validation-provider',{attrs:{"name":"Код партнера или промокод"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"xx_ref","state":errors.length > 0 ? false:null,"name":"xx_ref"},model:{value:(_vm.formData.xx_ref),callback:function ($$v) {_vm.$set(_vm.formData, "xx_ref", $$v)},expression:"formData.xx_ref"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Номер, который сообщает вам человек, рекомендующий данный сайт. Если не знаете – оставьте пустым.'),expression:"'Номер, который сообщает вам человек, рекомендующий данный сайт. Если не знаете – оставьте пустым.'",modifiers:{"hover":true,"top":true}}],staticClass:"p-0",attrs:{"variant":"flat","size":"sm"}},[_vm._v(" ? ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4162328927)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Придумайте свой пароль","label-for":"xx_pass"}},[_c('validation-provider',{attrs:{"name":"Пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"xx_pass","name":"xx_pass","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"············"},model:{value:(_vm.formData.xx_pass),callback:function ($$v) {_vm.$set(_vm.formData, "xx_pass", $$v)},expression:"formData.xx_pass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Соглашение","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","state":_vm.formData.status,"name":"checkbox-1"},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_vm._v(" Я согласен с "),_c('b-link',{attrs:{"href":_vm.server + '/confidence',"target":"blank"}},[_vm._v(" политикой конфиденциальности и условиями ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.loading,"type":"submit"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Зарегистрироваться")])],1)])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2 mb-2"},[_c('span',[_vm._v("Уже есть аккаунт? ")]),_c('b-link',{attrs:{"to":{ name:'login', query: Object.assign({}, _vm.$route.query) }}},[_c('span',[_vm._v("Войти")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }